import { StatusBar } from 'expo-status-bar';
import { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Linking, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Video } from 'expo-av'; // Importing the Video component from expo-av

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function TwoShows(props) {
  const { event, event2, toDirections, FS, FSS, randomURI1, randomURI2 } = props;
  const [hoveredView1, setHoveredView1] = useState(false);  // For the first large view
  const [hoveredView2, setHoveredView2] = useState(false);  // For the second large view
  const [videoSource1, setVideoSource1] = useState(null);  // Store random video source for view 1
  const [videoSource2, setVideoSource2] = useState(null);  // Store random video source for view 2
  const [videoPosition, setVideoPosition] = useState({ x: 0, y: 0 });

  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet

  // Function to get a random video path
  const getRandomVideo = () => {
    const videos = [
      require('../assets/jacoozy3.MOV'),
      require('../assets/jacoozy2.MOV'),
      require('../assets/jacoozy.mp4')
    ];
    return videos[Math.floor(Math.random() * videos.length)];
  };

  const handleMouseEnterView1 = () => {
    setHoveredView1(true);
    setVideoSource1(getRandomVideo());  // Set random video for view 1 on hover
  };

  const handleMouseLeaveView1 = () => {
    setHoveredView1(false);
    setVideoPosition({ x: 0, y: 0 });  // Reset position when mouse leaves
  };

  const handleMouseEnterView2 = () => {
    setHoveredView2(true);
    setVideoSource2(getRandomVideo());  // Set random video for view 2 on hover
  };

  const handleMouseLeaveView2 = () => {
    setHoveredView2(false);
    setVideoPosition({ x: 0, y: 0 });  // Reset position when mouse leaves
  };

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const rect = event.target.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
  
    // Calculate the mouse distance from the center
    const offsetX = clientX - centerX;
    const offsetY = clientY - centerY;
  
    // Invert the direction of the movement
    const invertedOffsetX = -offsetX; // Move in the opposite direction
    const invertedOffsetY = -offsetY; // Move in the opposite direction
  
    const maxOffsetX = rect.width / 24; // Limit of 25% of the view's width
    const maxOffsetY = rect.height / 24; // Limit of 25% of the view's height
  
    // We can scale the movement, but we won't scale beyond the max offset
    const clampedX = Math.min(maxOffsetX, Math.max(-maxOffsetX, invertedOffsetX));
    const clampedY = Math.min(maxOffsetY, Math.max(-maxOffsetY, invertedOffsetY));
  
    // Apply smooth transition
    setVideoPosition({ x: clampedX, y: clampedY });
  };

  function toWebsite(url) {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: ", url);
      }
    });
  }
  const event1NoTickets = event?.tickets === null || event?.tickets === undefined || event?.tickets === "" || event?.tickets === " ";
  const event2NoTickets = event2?.tickets === null || event2?.tickets === undefined || event2?.tickets === "" || event2?.tickets === " ";

  return (
    <View style={{ flexDirection: 'row', width: FS.width, justifyContent: 'center', marginBottom: 20 }}>
      {/* First Large View */}
      <View
        style={{
          backgroundColor: 'red',
          marginRight: 20,
          borderRadius: 10,
          width: FS.width / 2.5,
          height: FS.height / 2,  // Parent view's height and width are proportional to the window
        }}
        onMouseEnter={handleMouseEnterView1}
        onMouseLeave={handleMouseLeaveView1}
        onMouseMove={handleMouseMove} // Apply the mouse move to the container, not specific to one
      >
        <Image
          source={randomURI1}
          style={{ height: '100%', width: '100%', borderRadius: 10 }} // Image takes full size of parent
        />
        <LinearGradient colors={['rgba(0,0,0,0.9)', 'transparent']} style={{ width: '100%', height: '35%', position: 'absolute', top: 0, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
        <LinearGradient colors={['transparent', 'rgba(0,0,0,0.9)']} style={{ width: '100%', height: '35%', position: 'absolute', bottom: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}} />
        
        {/* Conditionally render the video over the image on hover */}
        {hoveredView1 && videoSource1 && (
            <View
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: FS.width / 2.5,
                height: FS.height / 2,
                justifyContent: 'center',  // Center the video vertically
                alignItems: 'center',  // Center the video horizontally
                overflow: 'hidden'
              }}
            >
              <Video
                source={videoSource1}
                style={{
                  width: FSS * 640,  // Apply scaling factor for width
                  height: (FSS * 640) * (9 / 16),  // Apply scaling factor for height to maintain 16:9 aspect ratio
                  transform: [
                    { translateX: videoPosition.x },
                    { translateY: videoPosition.y },
                  ], // Only move the video, not the red square
                  transition: 'transform 0.1s ease-out', // Add smooth transition here
                }}
                shouldPlay
                isLooping
                isMuted={true}
                videoStyle={{
                  width: FSS * 640,  // Apply scaling factor for width
                  height: (FSS * 640) * (9 / 16),  // Apply scaling factor for height to maintain 16:9 aspect ratio
                }}
              />
            </View>
          )}

        <View style={{ position: 'absolute', top: 10, flexDirection: 'row', justifyContent: 'space-evenly', left: 10 }}>
          {!event1NoTickets && (
            <TouchableOpacity
              onPress={() => toWebsite(event?.tickets)}
              style={{ height: 75, width: 100 * FSS, backgroundColor: '#56dc99', borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}
            >
              <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 15 * FSS }}>Tickets</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={{ position: 'absolute', left: 10, bottom: 25 }}>
          <Text style={[styles.name]}>{event.name}</Text>
          <Text style={[styles.location]}>{event.location} - {event.time || 'TBD'}</Text>
        </View>
        <View style={{ backgroundColor: 'white', position: 'absolute', right: 10, top: 10, justifyContent: 'center', alignItems: 'center', aspectRatio: 1, borderRadius: 5 }}>
          <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 16 * FSS, color: 'black', textAlign: 'center', width: FSS * 65 }}>{event.date}</Text>
        </View>
      </View>

      {/* Second Large View */}
      {event2 && (
        <View
          style={{ borderRadius: 10, width: FS.width / 2.5, height: FS.height / 2 }}
          onMouseEnter={handleMouseEnterView2}
          onMouseLeave={handleMouseLeaveView2}
          onMouseMove={handleMouseMove}
        >
          <Image
            source={randomURI2}
            style={{ height: '100%', width: '100%', borderRadius: 10 }}
          />
          <LinearGradient colors={['rgba(0,0,0,0.9)', 'transparent']} style={{ width: '100%', height: '35%', position: 'absolute', top: 0, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
          <LinearGradient colors={['transparent', 'rgba(0,0,0,0.9)']} style={{ width: '100%', height: '35%', position: 'absolute', bottom: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}} />
          
          {/* Conditionally render the video over the image on hover */}
          {hoveredView2 && videoSource2 && (
            <View
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: FS.width / 2.5,
                height: FS.height / 2,
                justifyContent: 'center',  // Center the video vertically
                alignItems: 'center',  // Center the video horizontally
                overflow: 'hidden',
                borderRadius: 10,
              }}
            >
              <Video
                source={videoSource2}
                style={{
                  width: FSS * 640,  // Apply scaling factor for width
                  height: (FSS * 640) * (9 / 16),  // Apply scaling factor for height to maintain 16:9 aspect ratio
                  transform: [
                    { translateX: videoPosition.x },
                    { translateY: videoPosition.y },
                  ], // Only move the video, not the red square
                  transition: 'transform 0.1s ease-out', // Add smooth transition here
                  borderRadius: 10,
                }}
                shouldPlay
                isLooping
                isMuted={true}
                videoStyle={{
                  width: FSS * 640,  // Apply scaling factor for width
                  height: (FSS * 640) * (9 / 16),  // Apply scaling factor for height to maintain 16:9 aspect ratio
                }}
              />
            </View>
          )}

          <View style={{ position: 'absolute', top: 10, flexDirection: 'row', justifyContent: 'space-evenly', left: 10 }}>
            {!event2NoTickets && (
              <TouchableOpacity
                onPress={() => toWebsite(event2?.tickets)}
                style={{ height: 75, width: 100 * FSS, backgroundColor: '#56dc99', borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}
              >
                <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 15 * FSS }}>Tickets</Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={{ position: 'absolute', left: 10, bottom: 20 }}>
            <Text style={[styles.name]}>{event2?.name}</Text>
            <Text style={[styles.location]}>{event2?.location} - {event2?.time || 'TBD'}</Text>
          </View>
          <View style={{ backgroundColor: 'white', position: 'absolute', right: 10, top: 10, justifyContent: 'center', alignItems: 'center', aspectRatio: 1, borderRadius: 5 }}>
            <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 16 * FSS, color: 'black', textAlign: 'center', width: FSS * 65 }}>{event2?.date}</Text>
          </View>
        </View>
      )}
    </View>
  );
}

const makeStyles = fontScale =>
  StyleSheet.create({
    name: {
      fontFamily: 'CG-Times Bold',
      fontSize: 30 * fontScale,
      color: 'white',
      paddingLeft: 5,
    },
    location: {
      fontFamily: 'CG-Times Bold',
      fontSize: 20 * fontScale,
      color: 'white',
      paddingLeft: 5,
    },
  });
