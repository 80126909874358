import { useState, useEffect, useRef, useMemo } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Linking, Platform } from 'react-native';
import { useFonts } from 'expo-font';
import { ref, onValue } from "firebase/database";
import { db } from '../config';
import { LinearGradient } from 'expo-linear-gradient';
import TwoShows from './TwoShows';

export default function EventList(props) {
  const [fontsLoaded] = useFonts({
    'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
  const [events, setEvents] = useState([]);
  const [FS, setFS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight) });
  const [FSS, setFSS] = useState(Math.min(window.innerWidth / 640, window.innerHeight / 640));

  const [imageArray] = useState([
    require('../assets/bandImages/4some.jpeg'),
    require('../assets/bandImages/Horizontal/H3.png'),
    require('../assets/bandImages/Horizontal/H1.png'),
    require('../assets/bandImages/Horizontal/H5.png'),
    require('../assets/bandImages/Horizontal/H4.png'),
  ]);

  const [imageAssignments, setImageAssignments] = useState({}); // Mapping show id to assigned image
  const imageIndexRef = useRef(0); // Start at the first image

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
      window.removeEventListener('resize', changeTitleSize);
    };
  }, []);

  useEffect(() => {
    readData();
  }, []);

  // Shuffle images
  function shuffleImages(images) {
    let shuffled = [...images];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }
    return shuffled;
  }

  // Read events data from Firebase
  function readData() {
    const showsRef = ref(db, 'shows');
    onValue(showsRef, (snapshot) => {
      const data = snapshot.val();
      setEvents(data);
    });
  }

  // Assign images to shows
  function assignImagesToShows() {
    const newAssignments = {};
    let imageIndex = 0;
    events.forEach(event => {
      if (!newAssignments[event.id]) { // Check if image is already assigned
        newAssignments[event.id] = imageArray[imageIndex];
        imageIndex = (imageIndex + 1) % imageArray.length;
      }
    });
    setImageAssignments(newAssignments);
  }

  // Call this once when events are fetched
  useEffect(() => {
    if (events.length > 0) {
      assignImagesToShows();
    }
  }, [events]);

  function toWebsite(url) {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: ", url);
      }
    });
  }

  function toDirections(lat, long) {
    if (Platform.OS !== 'web' || Platform.OS !== 'android') {
      Linking.openURL(`google.navigation:q=${lat},+${long}`);
    }
    if (Platform.OS !== 'ios') {
      Linking.openURL(`maps://app?saddr=My+Location&daddr=${lat},+${long}`);
    }
  }

  function changeTitleSize() {
    setFS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight) });
    setFSS(Math.min(window.innerWidth / 640, window.innerHeight / 640));
  }

  function renderItem(item, index) {
    if (index % 2 !== 0) return; // Skip every second item
    const event = item.event;
    const randomURI1 = imageAssignments[event.id] || null;
    const randomURI2 = imageAssignments[events[index + 1]?.id] || null; // Ensure image assignment for second show
    return (
      <View>
        <TwoShows
          toWebsite={toWebsite}
          toDirections={toDirections}
          makeStyles={makeStyles}
          FS={FS}
          FSS={FSS}
          event={event}
          event2={events[index + 1]}
          randomURI1={randomURI1}
          randomURI2={randomURI2} />
      </View>
    );
  }

  function renderSmallItem(item, index) {
    const event = item.event;
    const randomURI = imageAssignments[event.id] || null;
    return (
      <View style={{ width: FS.width, justifyContent: 'center', alignItems: 'center', marginBottom: FSS * 50, borderRadius: 10 }}>
        <View>
          <Image source={randomURI} style={{ height: FS.height / 2, width: FS.width - 25 , borderRadius: 10}} />
          <LinearGradient
            colors={['rgba(0,0,0,0.9)', 'transparent']}
            style={{ width: FS.width - 25, height: FS.height / 3.5, position: 'absolute', top: 0, borderTopRightRadius: 10, borderTopLeftRadius: 10 }}>
          </LinearGradient>
          <LinearGradient
            colors={['transparent', 'rgba(0,0,0,0.9)']}
            style={{ width: FS.width - 25, height: FS.height / 3.5, position: 'absolute', bottom: 0, borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>
          </LinearGradient>
          <View style={{ position: 'absolute', top: 10, flexDirection: 'row', justifyContent: 'space-evenly', left: 10 }}>
            {event?.tickets &&
              <TouchableOpacity onPress={() => toWebsite(event?.tickets)} style={{ height: 40, width: 200 * FSS, backgroundColor: '#56dc99', borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 20 * FSS }}>Tickets</Text>
              </TouchableOpacity>}
          </View>
          <View style={{ position: 'absolute', left: 10, bottom: 10 }}>
            <Text style={[styles.name]}>{event.name}</Text>
            <Text style={[styles.location]}>{event.location} - {event.time || 'TBD'}</Text>
          </View>
          <View style={{ backgroundColor: 'white', position: 'absolute', right: 10, top: 10, justifyContent: 'center', alignItems: 'center', aspectRatio: 1, borderRadius: 5 }}>
            <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 18 * FSS, color: 'black', textAlign: 'center', width: FSS * 75, padding: 10 }} numberOfLines={2}>{event?.date}</Text>
          </View>
        </View>
      </View>
    );
  }

  // Memoize the rendering of the list items
  const listItems = useMemo(() => {
    return events?.map((event, index) =>
      FS.width < 900 ? renderSmallItem({ event }, index) : renderItem({ event }, index)
    );
  }, [events, imageAssignments, FS]); // Re-run when `events` or `FS.width` or `imageAssignments` change

  const isIphone = FS.width < 900;
  return (
    <View style={{ marginTop: props.fromHome ? (isIphone ? 150 : 125) : 50, justifyContent: 'center', alignItems: 'center' }}>
      {listItems}
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'CG-Times Bold',
    fontSize: 40 * fontScale,
    color: 'white',
    marginTop: 50
  },
  name: {
    fontFamily: 'CG-Times Bold',
    fontSize: 30 * fontScale,
    color: 'white',
    paddingLeft: 5,
  },
  location: {
    fontFamily: 'CG-Times Bold',
    fontSize: 20 * fontScale,
    color: 'white',
    paddingLeft: 5
  },
  time: {
    fontFamily: 'CG-Times Bold',
    fontSize: 20 * fontScale,
    color: 'white',
    padding: 5
  },
});
