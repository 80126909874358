import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Animated, Dimensions, Linking, Image } from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, useContext, useCallback, useMemo } from 'react';
import { Audio } from 'expo-av';
import EventList from '../common/EventList';
import SongPlayer from '../common/SongPlayer';
import MusicButton from '../common/MusicButton';
import PhoneSongPlayer from '../common/PhoneSongPlayer';
import { LinearGradient } from 'expo-linear-gradient';
import { child, ref, onValue } from "firebase/database";
import { db } from '../config';
import { MainContext } from '../Contexts/mainContext';

const { height, width } = Dimensions.get('window');

export default function Home(props) {
  const [title, setTitle] = useState("Jacoozy");
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640));
  const [FSS, setFSS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight) });
  const [fontsLoaded] = useFonts({
    'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const [songCounts, setSongCounts] = useState([]);
  const fadeAnim = useRef(new Animated.Value(0)).current;  // Title animation
  const eventListFadeAnim = useRef(new Animated.Value(0)).current; // Event list animation
  const buttonFadeAnims = [
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current,
    useRef(new Animated.Value(0)).current
  ]; // Individual button fade animations
  const songPlayerFadeAnim = useRef(new Animated.Value(0)).current; // SongPlayer fade-in
  const { scrollPosition, setScrollPosition } = useContext(MainContext);

  useEffect(() => {
    readData();
    window.addEventListener('resize', changeTitleSize);
    return () => {
      window.removeEventListener('resize', changeTitleSize);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition >= 550) animateEventList();
  }, [scrollPosition]);

  useEffect(() => {
    const catchEmAll = async () => {
      await fetch('https://jacoozy-api.onrender.com/webhook', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          passphrase: "huyter"
        }),
      });
      readData();
    };
    catchEmAll();
  }, []);

  function readData() {
    const showsRef = ref(db, 'counts');
    onValue(showsRef, (snapshot) => {
      const data = snapshot.val();
      setSongCounts(data);
    });
  }

  function changeTitleSize() {
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640));
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight) });
  }

  const handleClick = (provider) => {
    let link = null;
    if (provider === 'Spotify') link = 'https://open.spotify.com/artist/65nqeHILTPeRfAMMSMqFl4';
    if (provider === 'Apple') link = 'https://music.apple.com/us/artist/jacoozy/1602621098';
    if (provider === 'Amazon') link = 'https://music.amazon.com/artists/B09PKQZSB8/jacoozy';
    Linking.canOpenURL(link).then(supported => {
      if (supported) {
        Linking.openURL(link);
      }
    });
  };

  function animateEventList() {
    Animated.timing(eventListFadeAnim, {
      toValue: 1,
      duration: 2500,
    }).start();
  }

  useEffect(() => {
    // Title animation
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,  // Fade in the title quickly
    }).start();

    // Button animations (fade in each button with different timings)
    Animated.timing(buttonFadeAnims[0], {
      toValue: 1,
      duration: 500,  // Fade-in duration for the first button (Apple Music)
    }).start();

    Animated.timing(buttonFadeAnims[1], {
      toValue: 1,
      duration: 1000, // Fade-in duration for the second button (Spotify)
    }).start();

    Animated.timing(buttonFadeAnims[2], {
      toValue: 1,
      duration: 1500, // Fade-in duration for the third button (Amazon Music)
    }).start();

    // Song Player animation
    Animated.timing(songPlayerFadeAnim, {
      toValue: 1,
      duration: 2000,
    }).start();
  }, []);

  // Use useCallback to memoize the event list rendering trigger
  const memoizedEventList = useMemo(() => {
    console.log("rendering")
    return <EventList fromHome={true} />;
  }, []);

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <View style={{ flexDirection: 'row', width: FSS.width, justifyContent: 'center' }}>
        {/* header */}
        <View style={{ position: 'absolute', zIndex: 999, alignSelf: 'center', top: FSS.width < 900 ? 100 : 150 }}>
          <View style={{ alignSelf: 'center' }}>
            <Animated.Text style={[styles.title, { opacity: fadeAnim }]}>Jacoozy</Animated.Text>
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'center', paddingBottom: 50, alignItems: 'center', bottom: 20 }}>
            {/* Stream now buttons */}
            <Animated.View style={{ opacity: buttonFadeAnims[0] }}>
              <MusicButton title="Apple Music" onPress={() => handleClick('Apple')} />
            </Animated.View>
            <Animated.View style={{ paddingLeft: FSS.width < 900 ? 30 : 90, paddingRight: FSS.width < 900 ? 30 : 90, opacity: buttonFadeAnims[1] }}>
              <MusicButton title="Spotify" onPress={() => handleClick('Spotify')} />
            </Animated.View>
            <Animated.View style={{ opacity: buttonFadeAnims[2] }}>
              <MusicButton title="Amazon Music" onPress={() => handleClick('Amazon')} />
            </Animated.View>
          </View>

          {/* Top 5 Songs */}
          <Animated.View style={{ opacity: songPlayerFadeAnim }}>
            {FSS.width < 900 ? <PhoneSongPlayer songCounts={songCounts} /> : <SongPlayer songCounts={songCounts} />}
          </Animated.View>
        </View>

        <View style={{ width: FSS.width }}>
          <Image
            source={require('../assets/bandImages/purpleBand.jpg')}
            style={{ height: FSS.height / 0.8, width: FSS.width }}
          />
        </View>
        <LinearGradient
          colors={['rgba(0,0,0,0.9)', 'transparent']}
          style={{ width: FSS.width, height: FSS.height / 1.5, position: 'absolute', top: 0 }}
        />
        <LinearGradient
          colors={['transparent', 'rgba(0,0,0,0.9)']}
          style={{ width: FSS.width, height: FSS.height / 1.5, position: 'absolute', bottom: 0 }}
        />
      </View>

      {/* Live Shows */}
      <Animated.View style={{ opacity: eventListFadeAnim }}>
        {memoizedEventList}
      </Animated.View>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold',
    fontSize: 90 * fontScale,
    color: '#92e9be',
  },
  name: {
    fontFamily: 'CG-Times Bold',
    fontSize: 30 * fontScale,
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf: 'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold',
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold',
    marginLeft: 15
  },
  email: {
    color: 'white',
    fontFamily: 'CG-Times Bold',
    position: 'absolute',
    bottom: 25,
    fontSize: 17
  }
});
