import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, Pressable } from 'react-native';
import Home from './Tabs/Home';
import Band from './Tabs/Band';
import Contact from './Tabs/Contact';
import Shows from './Tabs/Shows';
import Media from './Tabs/Media';
import TopHeader from './TopHeader';
import WebsiteHeader from './WebsiteHeader';
import WebsiteTabs from './WebsiteTabs';
import HamburgerMenu from './HamburgerMenu';
import {isMobile} from 'react-device-detect';
import Modal from "react-native-modal";
import FadeInText from './FadeInText';
import HamburgerMenuExpanded from './HamburgerMenuExpanded';
import { ScrollView } from 'react-native-web';
import Wave from './Wave';
import { MainContext } from './Contexts/mainContext';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function RootScreen() {
    const [tab, setTab] = useState('HomeScreen')
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [menuActivated, setMenuActivated] = useState(false)
    const [headerTriggerd, setHeaderTriggered] = useState();
    const [isScrolling, setIsScrolling] = useState(false)
    const {scrollPosition, setScrollPosition} = useContext(MainContext)
    const [eventListTriggered, setEventListTriggered] = useState(false)



    function handleWindowSizeChange() {
        setWidth( Dimensions.get('window').width);
        setHeight(Dimensions.get('window').height);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    // useEffect(() => {
    //     //console.log(width)
    // },[width])

    function switchTabs(screen, fromMenu, fromHeader){
        setTab(screen)
        if(fromMenu) setMenuActivated(false);
        if(fromHeader) setHeaderTriggered(true);
    }
    const scrollYRef = useRef(0)
  return (
    <View style={styles.container}>
      <View style={{width: width, height: 5, backgroundColor: "#0F0F0F", position: 'absolute'}}/> {/* on safari, you can see a sliver of the background above the wave, so match the color of the wave at the top of it */}
        {(menuActivated && width < 900) && 
          <HamburgerMenuExpanded 
            switchTabs={switchTabs} 
            width={width} 
            height={height}
          />
        }
        <TopHeader switchTabs={switchTabs} width={width} headerTriggerd={headerTriggerd} 
          setHeaderTriggered={setHeaderTriggered}  menuActivated={menuActivated} setMenuActivated={setMenuActivated}/>
        <ScrollView scrollEventThrottle={16} onScroll={(event) => {
          // 0 means the top of the screen, 100 would be scrolled 100px down
          const currentYPosition = event.nativeEvent.contentOffset.y
          const oldPosition = scrollYRef.current
          if(oldPosition < currentYPosition) {
              setIsScrolling(true)
              if(eventListTriggered) return;
              if(currentYPosition >= 550) {
                setEventListTriggered(true)
                setScrollPosition(currentYPosition)
              }
              //console.log("we mmovine down", currentYPosition)
          } else {
              setIsScrolling(true)
              //console.log("we mmovine up", currentYPosition)
          }
          scrollYRef.current = currentYPosition
        }} style={{flex: 1, marginTop: 100,}}>
        {tab === 'HomeScreen' && <Home isScrolling={isScrolling}/>}
        {tab === 'BandScreen' && <Band/>}
        {tab === 'ContactScreen' && <Contact/>}
        {tab === 'ShowsScreen' && <Shows/>}
        {tab === 'MediaScreen' && <Media/>}
        </ScrollView> 

        
        {/* <WebsiteHeader switchTabs={switchTabs}/>
        {width < 900 ? 
        <HamburgerMenu menuActivated={menuActivated} setMenuActivated={setMenuActivated}/> 
        : 
        <WebsiteTabs 
          headerTriggerd={headerTriggerd} 
          setHeaderTriggered={setHeaderTriggered} 
          switchTabs={switchTabs}
        />
        } */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#92e9be',
  },
  fadeText: {
    flex: 5,
    alignItems: 'center',
    justifyContent: 'center'
  }
});
