import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, FlatList, TextInput, TouchableOpacity, Linking } from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState } from 'react';
import { child, ref, update, onValue } from "firebase/database";
import { db } from '../config';

const { height, width } = Dimensions.get('window');

export default function SecretPage() {
  const [fontsLoaded] = useFonts({
    'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const [addingShow, setAddingShow] = useState(false);
  const [shows, setShows] = useState([]);
  const [modifiedShows, setModifiedShows] = useState([]); // To track the modified order
  const [forceShowUpdate, setForceShowUpdate] = useState(false);
  const [FS, setFS] = useState(Math.min(width / 640, height / 640));  // Font scale based on window size
  const [FSS, setFSS] = useState({ "width": width, "height": height });
  const [venueName, setVenueName] = useState('');
  const [cityState, setCityState] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [ticketURL, setTicketURL] = useState('');
  const [editingIndex, setEditingIndex] = useState(null); // To track the index of the edited show
  const [hasChanges, setHasChanges] = useState(false); // To track if there are unsaved changes
  const percentWidth = FSS.width < 900 ? '75%' : '25%'; // Adjust show card width based on screen size
  const styles = makeStyles(FS);

  const isReadyToSend = (
    venueName && cityState && date
  );

  useEffect(() => {
    if (addingShow) return;
    readData();
    window.addEventListener('resize', changeTitleSize);
    return () => {
      window.removeEventListener('resize', changeTitleSize);
    };
  }, []);

  useEffect(() => {
    checkForChanges();
  }, [modifiedShows]);

  function changeTitleSize() {
    setFS(Math.min(width / 640, height / 640)); // Recalculate font size scale on window resize
    setFSS({ "width": width, "height": height });
  }

  function readData() {
    const showsRef = ref(db, 'shows');
    onValue(showsRef, (snapshot) => {
      const data = snapshot.val();
      setShows(data || []);
      setModifiedShows(data || []); // Initialize the modified list as well
    });
  }

  function checkForChanges() {
    // Check if shows and modifiedShows are different
    if (JSON.stringify(shows) !== JSON.stringify(modifiedShows)) {
      setHasChanges(true); // There are unsaved changes
    } else {
      setHasChanges(false); // No changes
    }
  }

  function writeData() {
    const updates = {};
    updates['/shows'] = modifiedShows; // Use the modified list here
    update(ref(db), updates);
  }

  function deleteShow(index) {
    const updatedShows = modifiedShows.filter((_, i) => i !== index);
    setModifiedShows(updatedShows);
    setForceShowUpdate(prev => !prev);
  }

  function moveShowUp(index) {
    if (index === 0) return;  // Can't move the first item up
    const updatedShows = [...modifiedShows];
    const temp = updatedShows[index];
    updatedShows[index] = updatedShows[index - 1];
    updatedShows[index - 1] = temp;
    setModifiedShows(updatedShows);
    setForceShowUpdate(prev => !prev);
  }

  function moveShowDown(index) {
    if (index === modifiedShows.length - 1) return;  // Can't move the last item down
    const updatedShows = [...modifiedShows];
    const temp = updatedShows[index];
    updatedShows[index] = updatedShows[index + 1];
    updatedShows[index + 1] = temp;
    setModifiedShows(updatedShows);
    setForceShowUpdate(prev => !prev);
  }

  function editShow(index) {
    const showToEdit = modifiedShows[index];
    setVenueName(showToEdit.name);
    setCityState(showToEdit.location);
    setDate(showToEdit.date);
    setTime(showToEdit.time);
    setTicketURL(showToEdit.tickets);
    setEditingIndex(index);
  }

  function saveEdit() {
    const updatedShow = {
      name: venueName,
      location: cityState,
      date: date,
      time: time,
      tickets: ticketURL
    };
    const updatedShows = [...modifiedShows];
    updatedShows[editingIndex] = updatedShow; // Update the show at the editing index
    setModifiedShows(updatedShows);
    setForceShowUpdate(prev => !prev);
    setEditingIndex(null); // Reset the editing state
  }

  function renderShow({ item, index }) {
    const isFirst = index === 0;
    const isLast = index === modifiedShows.length - 1;

    return (
      <View style={[styles.showCard, { width: percentWidth }]}>
        <View style={styles.showHeader}>
          <Text style={styles.showIndex}>#{index + 1}</Text>
          <TouchableOpacity onPress={() => deleteShow(index)} style={styles.deleteButton}>
            <Text style={styles.deleteText}>Delete</Text>
          </TouchableOpacity>
        </View>

        {/* Editable fields when in edit mode */}
        {editingIndex === index ? (
          <>
            <TextInput
              style={styles.showInput}
              value={venueName}
              onChangeText={setVenueName}
              placeholder="Venue Name"
            />
            <TextInput
              style={styles.showInput}
              value={cityState}
              onChangeText={setCityState}
              placeholder="City, State"
            />
            <TextInput
              style={styles.showInput}
              value={date}
              onChangeText={setDate}
              placeholder="Date"
            />
            <TextInput
              style={styles.showInput}
              value={time}
              onChangeText={setTime}
              placeholder="Time"
            />
            <TextInput
              style={styles.showInput}
              value={ticketURL}
              onChangeText={setTicketURL}
              placeholder="Ticket URL"
            />
            <TouchableOpacity onPress={saveEdit} style={styles.saveButton}>
              <Text style={styles.saveText}>Save Edit</Text>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <Text style={styles.showTitle}>{item.name}</Text>
            <Text style={styles.showDetails}>{item.location}</Text>
            <Text style={styles.showDetails}>{item.date} | {item.time}</Text>
            {item.tickets && (
              <Text style={styles.ticketLink} onPress={() => Linking.openURL(item.tickets)}>
                {item.tickets}
              </Text>
            )}
          </>
        )}

        {/* Arrows for reordering */}
        <View style={styles.arrowButtons}>
          <TouchableOpacity 
            onPress={() => moveShowUp(index)} 
            style={[styles.arrowButton, isFirst && styles.disabledArrow]}>
            <Text style={styles.arrowText}>↑</Text>
          </TouchableOpacity>
          <TouchableOpacity 
            onPress={() => moveShowDown(index)} 
            style={[styles.arrowButton, isLast && styles.disabledArrow]}>
            <Text style={styles.arrowText}>↓</Text>
          </TouchableOpacity>
        </View>

        {/* Edit Button */}
        {editingIndex !== index && (
          <TouchableOpacity onPress={() => editShow(index)} style={styles.editButton}>
            <Text style={styles.editText}>Edit</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  }

  function onSubmit() {
    const newShow = {
      id: Date.now().toString(), // Unique ID generated using the current timestamp
      name: venueName,
      location: cityState,
      date: date,
      time: time,
      tickets: ticketURL
    };
    const updatedShows = [...modifiedShows, newShow];
    setModifiedShows(updatedShows);
    setForceShowUpdate(prev => !prev);
    setAddingShow(false);
    setVenueName('');
    setCityState('');
    setDate('');
    setTime('');
    setTicketURL('');
  }

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />

      <Text style={styles.title}>Shows</Text>
    
      {!addingShow && (
        <TouchableOpacity onPress={() => setAddingShow(true)}>
          <Text style={styles.addButton}>+ Add Show</Text>
        </TouchableOpacity>
      )}
      {hasChanges && (
        <TouchableOpacity onPress={writeData} style={{ backgroundColor: '#56dc99',paddingVertical: 12,paddingHorizontal: 20,borderRadius: 8,marginTop: 20,width: '40%', justifyContent: 'center', alignItems: 'center'}}>
          <Text style={styles.saveText}>Save Changes</Text>
        </TouchableOpacity>
      )}

      {addingShow && (
        <View style={styles.inputContainer}>
          <TextInput
            placeholder="Venue Name"
            placeholderTextColor="gray"
            onChangeText={setVenueName}
            style={styles.input}
            value={venueName}
          />
          <TextInput
            placeholder="City, State"
            placeholderTextColor="gray"
            onChangeText={setCityState}
            style={styles.input}
            value={cityState}
          />
          <TextInput
            placeholder="Date"
            placeholderTextColor="gray"
            onChangeText={setDate}
            style={styles.input}
            value={date}
          />
          <TextInput
            placeholder="Time"
            placeholderTextColor="gray"
            onChangeText={setTime}
            style={styles.input}
            value={time}
          />
          <TextInput
            placeholder="Ticket URL"
            placeholderTextColor="gray"
            onChangeText={setTicketURL}
            style={styles.input}
            value={ticketURL}
          />
          <TouchableOpacity
            activeOpacity={isReadyToSend ? 0.7 : 1}
            disabled={!isReadyToSend}
            onPress={onSubmit}
            style={[styles.submitButton, !isReadyToSend && styles.disabledButton]}
          >
            <Text style={styles.submitText}>Submit</Text>
          </TouchableOpacity>
        </View>
      )}

      <FlatList
        data={modifiedShows}
        renderItem={renderShow}
        extraData={forceShowUpdate}
        keyExtractor={(item) => item.id} // Use the unique ID here
        style={styles.showList}
      />
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f4f4f9',
    paddingTop: 20
  },
  title: {
    fontFamily: 'CG-Times Bold',
    fontSize: 50 * fontScale,
    color: '#333',
    textAlign: 'center',
    marginBottom: 20
  },
  addButton: {
    fontSize: 18,
    color: '#56dc99',
    marginBottom: 20,
    fontWeight: 'bold'
  },
  inputContainer: {
    marginBottom: 30,
    padding: 20,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    width: '80%',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 10
  },
  input: {
    backgroundColor: '#f9f9f9',
    height: 40,
    borderRadius: 5,
    marginBottom: 15,
    paddingLeft: 10,
    fontSize: 16
  },
  submitButton: {
    width: '100%',
    paddingVertical: 12,
    backgroundColor: '#56dc99',
    borderRadius: 8,
    alignItems: 'center'
  },
  disabledButton: {
    backgroundColor: 'gray'
  },
  submitText: {
    color: '#ffffff',
    fontSize: 18
  },
  showCard: {
    width: '25%',
    backgroundColor: '#ffffff',
    padding: 20,
    marginBottom: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    alignSelf: 'center'
  },
  showHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  showIndex: {
    fontSize: 18,
    color: '#333',
    fontWeight: 'bold'
  },
  deleteButton: {
    backgroundColor: '#ff6666',
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5
  },
  deleteText: {
    color: '#ffffff',
    fontSize: 14
  },
  showTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginTop: 10
  },
  showDetails: {
    fontSize: 16,
    color: '#555',
    marginTop: 5
  },
  ticketLink: {
    fontSize: 16,
    color: '#007bff',
    marginTop: 10
  },
  showList: {
    width: '100%',
    marginTop: 20
  },
  arrowButtons: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    flexDirection: 'row',
  },
  arrowButton: {
    backgroundColor: '#56dc99',
    padding: 10,
    borderRadius: 5,
    marginHorizontal: 5
  },
  arrowText: {
    fontSize: 18,
    color: '#fff'
  },
  disabledArrow: {
    backgroundColor: '#cccccc',
  },
  saveButton: {
    backgroundColor: '#56dc99',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 8,
    marginTop: 20,
    width: '40%',
    position: 'absolute'
  },
  saveText: {
    color: '#fff',
    fontSize: 18
  },
  editButton: {
    backgroundColor: '#ffcc00',
    padding: 10,
    marginTop: 10,
    borderRadius: 8,
    alignItems: 'center',
    width: '25%'
  },
  editText: {
    color: '#fff',
    fontSize: 18
  }
});
