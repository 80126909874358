import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable, ScrollView, Image } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { Audio } from 'expo-av';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { FlatList, TouchableWithoutFeedback, TouchableHighlight } from 'react-native-web';
import SongBubble from './SongBubble';
import PlayButtonAndMore from './PlayButtonAndMore';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function PhoneSongPlayer(props){
  const [isPlaying, setIsPlaying] = useState(false)
  const [highlighted, setHighlighted] = useState(1)
  const [currentSongName, setCurrentSongName] = useState('Cold Hearted')
  const [currentSongDuration, setCurrentSongDuration] = useState(9)
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
    const [fontsLoaded] = useFonts({
      'CG-Times Bold': require('../assets/cgtr65w.otf'),
      'CG-Times': require('../assets/CG Times Regular font.otf'),
    });
    const songs = [
      {name: 'Cold Hearted', uri: require('../assets/Jacoozy.png'), song: require('../assets/ColdHearted.mp3')},
      {name: 'Blinds', uri: require('../assets/Blinds.jpeg'), song: require('../assets/Blinds.mp3')},
      {name: 'Lily', uri: require('../assets/Jacoozy.png'), song: require('../assets/Lily.mp3')},
      {name: 'Again', uri: require('../assets/Again.png'), song: require('../assets/Again.mp3')},
      {name: 'Cul De Sac', uri: require('../assets/Jacoozy.png'), song: require('../assets/CulDeSac.mp3')},
    ]
  const [currentSongPath, setCurrentSongPath] = useState(songs[0].song)
  const [soundState, setSoundState] = useState();
  const [Loaded, SetLoaded] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const sound = useRef(new Audio.Sound());
  const [progress, setProgress] = useState(0)
  const [finalDuration, setFinalDuration] = useState(0)
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    selectSong(1,songs[0].name, songs[0].song, 9)
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function selectSong(index, songName, songPath, duration){
    sound.current.unloadAsync();
    stop()
    LoadAudio(songPath)
    setIsPlaying(false)
    setProgress(0)
    setDuration(0)
    setFinalDuration(0)
    setHighlighted(index)
    setCurrentSongName(songName)
    setCurrentSongPath(songPath)
    setCurrentSongDuration(duration)
  }

  const PlayAudio = async () => {
    try {
      const result = await sound.current.getStatusAsync();

      if (result.isLoaded) {
        if (result.isPlaying === false) {
          sound.current.playAsync();
          startCounting();
        }
      }
    } catch (error) {}
  };

  const PauseAudio = async () => {
    try {
      const result = await sound.current.getStatusAsync();
      if (result.isLoaded) {
        if (result.isPlaying === true) {
          sound.current.pauseAsync();
          stop();
        }
      }
    } catch (error) {}
  };

  const LoadAudio = async (songPath) => {
    SetLoading(true);
    const checkLoading = await sound.current.getStatusAsync();
      try {
  
        const result = await sound.current.loadAsync(songPath, {}, true);
  
        if (result.isLoaded === false) {
          SetLoading(false);    
        } else {
          SetLoading(false);
          SetLoaded(true);
        }
      } catch (error) {  
        SetLoading(false);
      }
  };

  const intervalRef = useRef(); //create a ref for interval
  const durationRef= useRef();
  
  const startCounting = () => {
    //assign interval ref here
    startDuration()
    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev === 10*currentSongDuration) {
          stop();
          setIsPlaying(false)
          setFinalDuration(0)
          return 0;
        }
        calcFill(prev + 1)
        return prev + 1;
      });
    }, 100);
  };

  const startDuration = () => {
    durationRef.current = setInterval(() => {
      setDuration((prev) => {
        if (prev === currentSongDuration) {
          stop();
          setIsPlaying(false)
          setFinalDuration(0)
          return 0;
        }
        return prev + 1;
      });
    }, 1000);
  }

  const stop = () => {
    //clear the interval ref
    clearInterval(intervalRef.current);
    clearInterval(durationRef.current);
  };

  async function playSong(){
    isPlaying ? PauseAudio() : PlayAudio()
    setIsPlaying(!isPlaying)
  }

  function calcFill(p){
    const steps = (100 / currentSongDuration)/10
    setFinalDuration(p * steps)
  }

  const againStream = props.songCounts.count4, coldHeartedStream = props.songCounts.count1, culDeSacStream = props.songCounts.count5, blindsStream = props.songCounts.count2, lilyStream = props.songCounts.count3
  const againTotalDuration = 13, coldHeartedDuration = 9, culDeSacDuration = 12, lilyDuration = 7, detourDuration = 6
  const first = '1)', second = '2)', third = '3)', fourth = '4)', fifth = '5)'
  return(
    <View style={{width: 375, height: 200, backgroundColor: '#92e9be', alignSelf: 'center', borderRadius: 20, justifyContent: "center" }}>

      <View style={{flexDirection: 'row'}}>
        <Image style={{width: 160, height: 170, borderTopLeftRadius: 10, borderBottomLeftRadius: 10 ,left: 15}}
          source={{uri: songs[highlighted-1].uri}}
       />

        <View style={{height: 100}}>
          <TouchableWithoutFeedback onPress={() => selectSong(1,'Cold Hearted', songs[0].song, 9)}> 
          <View  style={{width: 180, height: 30, left: 30, flexDirection: 'row', alignItems: 'center', backgroundColor: highlighted === 1 ? 'rgba(0,0,0,0.07)' : 'transparent', borderRadius: 5}}>
            <Text style={{paddingRight: 10, fontFamily: 'CG-Times'}}>{first}</Text>
            <Text style={{fontFamily: 'CG-Times'}}>{songs[0].name}</Text>
            <Text style={{position: 'absolute', right: 0, fontFamily: 'CG-Times'}}>{coldHeartedStream}</Text>
          </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback onPress={() => selectSong(2,'Blinds', songs[1].song, 11)}> 
          <View  style={{width: 180, height: 30, left: 30, flexDirection: 'row', alignItems: 'center', backgroundColor: highlighted === 2 ? 'rgba(0,0,0,0.07)' : 'transparent', borderRadius: 5}}>
            <Text style={{paddingRight: 10, fontFamily: 'CG-Times'}}>{second}</Text>
            <Text style={{fontFamily: 'CG-Times'}}>{songs[1].name}</Text>
            <Text style={{position: 'absolute', right: 0, fontFamily: 'CG-Times'}}>{blindsStream}</Text>
          </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback onPress={() => selectSong(3,'Lily', songs[2].song, 7)}> 
          <View  style={{width: 180, height: 30, left: 30, flexDirection: 'row', alignItems: 'center', backgroundColor: highlighted === 3 ? 'rgba(0,0,0,0.07)' : 'transparent', borderRadius: 5}}>
            <Text style={{paddingRight: 10, fontFamily: 'CG-Times'}}>{third}</Text>
            <Text style={{fontFamily: 'CG-Times'}}>{songs[2].name}</Text>
            <Text style={{position: 'absolute', right: 0, fontFamily: 'CG-Times'}}>{lilyStream}</Text>
          </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback onPress={() =>  selectSong(4,'Again', songs[3].song, 13)}>
          <View  style={{width: 180, height: 30, left: 30, flexDirection: 'row', alignItems: 'center', backgroundColor: highlighted === 4 ? 'rgba(0,0,0,0.07)' : 'transparent', borderRadius: 5}}>
            <Text style={{paddingRight: 10, fontFamily: 'CG-Times'}}>{fourth}</Text>
            <Text style={{fontFamily: 'CG-Times'}}>{songs[3].name}</Text>
            <Text style={{position: 'absolute', right: 0, fontFamily: 'CG-Times'}}>{againStream}</Text>
          </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback onPress={() => selectSong(5,'Cul De Sac', songs[4].song, 12)}> 
          <View  style={{width: 180, height: 30, left: 30, flexDirection: 'row', alignItems: 'center', backgroundColor: highlighted === 5 ? 'rgba(0,0,0,0.07)' : 'transparent', borderRadius: 5}}>
            <Text style={{paddingRight: 10, fontFamily: 'CG-Times'}}>{fifth}</Text>
            <Text style={{fontFamily: 'CG-Times'}}>{songs[4].name}</Text>
            <Text style={{position: 'absolute', right: 0, fontFamily: 'CG-Times'}}>{culDeSacStream}</Text>
          </View>
          </TouchableWithoutFeedback>
          
          <View style={{left: 30, top: 5}}>
           <Text style={{ fontFamily: 'CG-Times'}}>{currentSongName}</Text>
          </View>
          
          {/* <View style={{left: 125, }}>
          <SongBubble songName={currentSongName} songRank={first} songStreams={coldHeartedStream} totalDuration={coldHeartedDuration} songPath={require('../assets/ColdHearted.mp3')}/>
          </View> */}

          <View style={{left: 125, bottom: 20 }}>
          <PlayButtonAndMore currentSongPath={currentSongPath} totalDuration={currentSongDuration} onPress={playSong} isPlaying={isPlaying} progress={finalDuration}/>
          </View> 

        </View>

      </View>

    </View>
  )
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: 'white'
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 55,
    padding: 10,
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 16,
    fontFamily: 'CG-Times', 
    marginLeft: 10
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
